Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MapSettings";
exports.labelBodyText = "MapSettings Body";
exports.getshuttledriverforyou='bx_block_location/geo_locations/shuttle_driver_for_you'
exports.btnExampleTitle = "CLICK ME";
exports.nearestDrivers = "bx_block_mapsettings/mapsettings/get_nearest_shuttle_drivers"
// Customizable Area End