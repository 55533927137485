//@ts-nocheck
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require('./config')

// Customizable Area End
export interface Props {
  // Customizable Area Start
  navigation: any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  coordinates:string;
  isLoading: boolean;
  shuttleDetails: {[keyVal:string]: string | number}[];

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class MapSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getApicallData: string = "";
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      isLoading: false,
      coordinates: ([
        {
          latitude: 22.08241,
          longitude: 83.471558,
        }, {
          latitude: 22.1748,
          longitude:  83.3533
        },
        {
          latitude: 22.178033,
          longitude:  83.344289
        },
        {
          latitude: 22.172866,
          longitude:  83.348215
        },
        {
          latitude: 22.169001,
          longitude:  83.348602
        },
      ]),
      shuttleDetails:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.receive = this.receive.bind(this);  
    
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    )
    this.handleSetData(responseJson)
    // Customizable Area End
    }

    // Customizable Area Start

    handleSetData = (responseJson:{roo_driver: {[keyVal:string]:string | number}, shuttle_drivers : {[keyVal:string]: string | number}[] }) =>{
      this.handleSetDriver(responseJson.roo_driver) ;
      this.handleSetShuttle(responseJson.shuttle_drivers);
    }
  
    handleSetDriver = (data: {[keyVal:string]:string | number}) => {
      this.setState({
        coordinates: data
      })
    };
  
    handleSetShuttle = (data:{[keyVal:string]: string | number}[] ) => {
      this.setState({
        shuttleDetails: data
      })
    };



    async componentDidMount() {
      this.getNearestDriverData();
    }
  
    getNearestDriverData() {

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token:
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTc3NywiZXhwIjoxNjgxMjI5MTgwfQ.apCn-5GU9CHbPaeWvvCvkvt5-fvp65zdKDhrx2-1wQiPxJq4oDZI9l7RDFChfvnj05NC07TW3FMjT5F3zSsbDw",
      };
  
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getApicallData = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${configJSON.nearestDrivers}?driver_current_lat=22.169001&driver_current_lng=83.348602`
       );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  // web events

}
  // Customizable Area End