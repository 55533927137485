//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { caricon, Flag } from './assets';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import MapSettingsController, {
  Props,
  configJSON,
} from "./MapSettingsController";

class MapSettings extends MapSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Box>
  
          <Map
          className="location-map"
            google={this.props.google}
            zoom={14}
            style={mapStyles}
            initialCenter={
              {
                lat: Number(this.state.coordinates.driver_latitude ),
                lng: Number(this.state.coordinates.driver_longitude )
              }
            }
            center={
              {
                lat: Number(this.state.coordinates.driver_latitude ),
                lng: Number(this.state.coordinates.driver_longitude )
              }
            }
          >
            <Marker
    position={  {
      lat: Number(this.state.coordinates.driver_latitude ),
      lng: Number(this.state.coordinates.driver_longitude )
    }}
    icon={{
      url: `${Flag}`,
      scaledSize: new this.props.google.maps.Size(40, 60),
    }} />
            
    {this.state.shuttleDetails.map((element: {[keyVal:string]: string | number } )=>
       <Marker
       position={{lat: Number(element.latitude) , lng: Number(element.longitude)}}
       icon={{
         url: `${caricon}`,
      scaledSize: new this.props.google.maps.Size(60, 60),
        
       }} />  
    )}
      
            </Map>
             
      </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const mapStyles = {
  width: '70%',
  height: '70%',
  margin: "auto",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const webStyle = {
  Mapimage: {
    position: "relative",
    height: "140vh",

  } as React.CSSProperties,  
};
// Customizable Area End
export default GoogleApiWrapper({
  apiKey: 'AIzaSyD24Z2ObJBO-bVH33RyS2Dlj5Ht6SsfqIo'
})(MapSettings);