// App.js - WEB
import React, { Component } from 'react';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { View } from 'react-native';
import { ModalContainer } from 'react-router-modal';
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTopButton from 'react-scroll-to-top';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import DownloadOptions from '../../blocks/DownloadOptions/src/DownloadOptions.web';
//import WebRoutes from './WebRoutes';
import { ToastContainer } from 'react-toastify';
import WelcomeScreen from '../../blocks/LanguageSupport/src/WelcomeScreen.web';
import PhoneVerification from '../../blocks/LanguageSupport/src/PhoneVerification.web';
import MapSettings from "../../blocks/MapSettings/src/MapSettings.web"

const routeMap = {
  HomeScreen: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  LanguageSupport: {
    component: WelcomeScreen ,
    path: '/LanguageSupport',
    exact: true
  },
  PhoneVerification: {
    component: PhoneVerification,
    path: '/PhoneVerification',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  DownloadOptions: {
    component: DownloadOptions,
    path: '/DownloadOptions'
  },
  MapSettings: {
    component: MapSettings,
    path: '/MapSettings'
  }
  
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loader: true });
  }

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
        {/* <ModalContainer /> */}
      </View>
    );
  }
}
export default App;
